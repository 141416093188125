<script setup>
import { ref, watch } from 'vue'

import { router } from '@inertiajs/vue3';
import Head from '../../Components/Head.vue';
import NavBar from '../../Components/Navbar.vue';
import Footer from '../../Components/Footer.vue';
import ModelPicker from '../../Components/ModelPicker.vue';
import Car from '../../Components/Car.vue';
import Usps from './Components/Usps.vue';

defineProps({
    meta: {
        type: Object,
    },
    showExtra: {
        type: Boolean,
    },
    hasCars: {
        type: Boolean,
    },
    cars: {
        type: Array,
    },
    carModels: {
        type: Array,
    },
    genericExteriorColors: {
        type: Array,
    },
});

const url = new URL(location.href);
const urlParams = url.searchParams;
const carModelSlug = ref(url.pathname.split('/')[1]);
const genericExteriorColor = ref(urlParams.get('kleur') || '');
const priceMax = ref(urlParams.get('prijs') || '');
const sorting = ref(urlParams.get('sortering') || '');

const refresh = () => {
    router.get(route('cars.index', carModelSlug.value), {
        ...(genericExteriorColor.value && { kleur: genericExteriorColor.value }),
        ...(priceMax.value && { prijs: priceMax.value }),
        ...(sorting.value && { sortering: sorting.value }),
    }, {
        preserveState: true,
        preserveScroll: true,
    })

    document.getElementById('results')
        .scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
}

watch(carModelSlug, async (newValue, oldValue) => {
    window.utag && window.utag.link({
        tealium_event: 'filter_selection',
        filterCategory: 'car model',
        filterChoice: newValue,
        eventCategory: 'filters',
        eventAction: 'car model',
        eventLabel: newValue,
    });
    refresh()
})
watch(genericExteriorColor, async (newValue, oldValue) => {
    window.utag && window.utag.link({
        tealium_event: 'filter_selection',
        filterCategory: 'exterior base color',
        filterChoice: newValue,
        eventCategory: 'filters',
        eventAction: 'exterior base color',
        eventLabel: newValue
    });
    refresh()
})
watch(priceMax, async (newValue, oldValue) => {
    window.utag && window.utag.link({
        tealium_event: 'filter_selection',
        filterCategory: 'price max',
        filterChoice: newValue,
        eventCategory: 'filters',
        eventAction: 'price max',
        eventLabel: newValue
    });
    refresh()
})
watch(sorting, async (newValue, oldValue) => {
    window.utag && window.utag.link({
        tealium_event: 'filter_selection',
        filterCategory: 'sorting',
        filterChoice: newValue,
        eventCategory: 'filters',
        eventAction: 'sorting',
        eventLabel: newValue
    });
    refresh()
})
</script>

<style>
option {
    color: black; /* this fixes white on white options in select boxes on Windows */
}
</style>

<template>
    <Head :meta="meta" :url="route('cars.index', carModelSlug)" />

    <NavBar />
    <header class="h-96 w-full h-96 flex items-center relative bg-[url(/resources/assets/img/steering-wheel@2x.webp)] bg-cover bg-[25%_center]">
        <div class="bg-black opacity-50 w-full h-full absolute"></div>
        <div class="container mx-auto px-4 relative">
            <h1 class="text-white font-light text-5xl mb-2">Direct een CUPRA<br class="hidden lg:inline"/> uit voorraad rijden</h1>
        </div>
    </header>

    <Usps />

    <main v-if="!hasCars" class="container mx-auto px-4 my-8" id="results">
        <div class="bg-copper text-white p-4 mb-2">
            In verband met onderhoud is de actuele voorraad momenteel niet online inzichtelijk.<br/><br/>
            Neem contact met ons op voor informatie over de actuele voorraad via <a href="tel:+31334949919" class="font-medium hover:underline">033 494 99 19</a> (maandag t/m zaterdag 10:00 - 18:00).
        </div>
    </main>

    <main v-else class="container mx-auto px-4 my-8" id="results">
        <div class="mb-8 xl:mx-32">
            <ModelPicker :car-models="carModels" v-model="carModelSlug" />
        </div>

        <div>
            <div class="grid grid-cols-1 sm:grid-cols-3 gap-6 lg:gap-8 text-cupgragray items-end">
                <!-- <div class="flex flex-col gap-2">
                    <label for="model">Model</label>
                    <select id="model" v-model="carModelSlug" class="h-12 bg-white border-cupragray focus:ring-copper focus:border-copper">
                        <option value="">Geen voorkeur</option>
                        <option v-for="carModel in carModels" :value="carModel.slug">{{ carModel.brand }} {{ carModel.model }} ({{ carModel.cars_count }})</option>
                    </select>
                </div> -->
                <div class="flex flex-col gap-2">
                    <label for="color">Kleur</label>
                    <select id="color" v-model="genericExteriorColor" class="h-12 bg-white border-cupgragray focus:ring-copper focus:border-copper">
                        <option value="">Geen voorkeur</option>
                        <option
                            v-for="exteriorColor in genericExteriorColors"
                            :value="exteriorColor.color_generic.toLowerCase()"
                            :disabled="!exteriorColor.cars_count"
                        >
                            {{ exteriorColor.color_generic }}
                            ({{ exteriorColor.cars_count }})
                        </option>
                    </select>
                </div>
                <div class="flex flex-col gap-2">
                    <label for="price_max">Prijs tot (€)</label>
                    <select id="price_max" v-model="priceMax" class="h-12 bg-white border-cupgragray focus:ring-copper focus:border-copper">
                        <option value="">Geen voorkeur</option>
                        <option value="40000">€ 40.000</option>
                        <option value="50000">€ 50.000</option>
                        <option value="60000">€ 60.000</option>
                        <option value="70000">€ 70.000</option>
                    </select>
                </div>
                <div class="flex flex-col gap-2">
                    <label for="sorting">Sorteren</label>
                    <select id="sorting" v-model="sorting" class="h-12 bg-white border-cupgragray focus:ring-copper focus:border-copper">
                        <option value="">Populariteit</option>
                        <option value="prijs_op">Prijs oplopend</option>
                        <option value="prijs_af">Prijs aflopend</option>
                    </select>
                </div>
            </div>

            <div class="my-8">
                <template v-if="cars.length === 0 && (carModelSlug || genericExteriorColor || priceMax)">
                    Geen CUPRA's gevonden die aan je filters voldoen.
                    Pas je filters aan of <a href="https://configurator.cupraofficial.nl/" target="_blank" class="font-bold hover:underline">stel je eigen CUPRA samen</a>!
                </template>
                <template v-else-if="cars.length === 0">
                    Helaas, momenteel zijn er geen CUPRA's voorradig.
                    Je kunt natuurlijk wel <a href="https://configurator.cupraofficial.nl/" target="_blank" class="font-bold hover:underline">je eigen CUPRA samenstellen</a>!
                </template>
                <!-- <template v-else-if="carModelSlug || genericExteriorColor || priceMax">
                    {{ cars.length }} CUPRA{{ cars.length === 1 ? '' : '\'s' }} direct leverbaar die aan je filters voldoe{{ cars.length === 1 ? 't' : 'n' }}.
                </template> -->
                <!-- <template v-else>
                    {{ cars.length }} CUPRA{{ cars.length === 1 ? '' : '\'s' }} direct leverbaar.
                </template> -->
            </div>

            <div v-if="cars.length > 0" class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 lg:gap-8 mt-8 mb-16">
                <Car v-for="car in cars" :car="car" :show-extra="showExtra" />
            </div>
        </div>
    </main>

    <Footer />
</template>
