<script setup>
import { Head, Link } from '@inertiajs/vue3';
import { computed } from 'vue';
import images360 from '@/images360';
import { EyeIcon } from '@heroicons/vue/24/solid';

const props = defineProps({
    car: {
        type: Object,
    },
    showExtra: {
        type: Boolean,
    },
});

const formatPrice = function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    return formatter.format(value);
}

const carImageUrl = computed(() => images360(props.car)[0]);
</script>

<template>
    <Link :href="route('cars.showBySlug', [car.car_model.slug, car.slug])"
        class="bg-white text-cupragray hover:shadow-lg scale-100 motion-safe:hover:scale-[1.01] transition-all duration-250 focus:outline focus:outline-2 focus:outline-copper">
        <div class="relative overflow-hidden p-6 h-full flex flex-col justify-between">
            <div>
                <div v-if="car.action_label" class="absolute right-0 top-0 h-16 w-16">
                    <div
                        class="absolute transform rotate-45 font-semibold py-1 right-[-40px] top-[40px] w-[200px] uppercase text-center"
                        :class="car.action_label_class ? car.action_label_class : 'bg-copper text-white'"
                    >
                        {{ car.action_label }}
                    </div>
                </div>
                <div v-if="showExtra" class="absolute right-0 top-0 mt-3 mr-3 flex items-center gap-1 text-gray-500 text-sm">
                    <EyeIcon class="h-4 w-4" /> {{ car.pageviews_count }}
                </div>

                <h2 class="text-2xl font-light text-center">
                    {{ car.car_model.brand }} {{ car.car_model.model }}
                </h2>
                <div class="font-bold text-center md:line-clamp-1">
                    {{ car.type }}
                </div>
                <div class="text-center">
                    {{ car.exterior_color.color }}
                </div>
            </div>

            <!-- Disabled images from own render service for consistency with PDP where 360 images are disabled -->
            <img v-if="false && carImageUrl" :src="carImageUrl" class="my-6 aspect-video" loading="lazy" />
            <img v-else-if="car.car_images[0]" :src="car.car_images[0].url" class="my-6 aspect-video object-cover" loading="lazy" />

            <div class="flex flex-col gap-3 items-center">
                <div v-if="car.price_discount" class="flex gap-4 justify-center">
                    <h3 class="line-through opacity-60">{{ formatPrice(parseFloat(car.price_net)) }}</h3>
                    <h3 class="font-bold"><span class="font-normal">Nu voor</span> {{ formatPrice(parseFloat(car.price_discount)) }}</h3>
                </div>
                <div v-else class="flex gap-4 justify-center">
                    <h3 class="font-bold"><span class="font-normal">Nu voor</span> {{ formatPrice(parseFloat(car.price_net)) }}</h3>
                </div>

                <!-- <div v-if="car.available_in_weeks" class="text-sm">
                    Levertijd: {{ car.available_in_weeks === 1 ? '1 week' : `${car.available_in_weeks} weken` }}
                </div> -->

                <button class="mt-2 border border-cupragray hover:bg-oxygen hover:text-black h-12 w-full">Bekijk deze auto &gt;</button>
            </div>
        </div>
    </Link>
</template>