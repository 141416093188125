export default function images(car) {
    if (!car.car_model.code || !car.model_year || !car.edition_code || !car.exterior_color.code || !car.rim_code || !car.lamp_code) {
        return [];
    }

    const url = (index) => `https://cgi.seat.nl/${car.car_model.code}/${car.model_year}/dark/${car.edition_code}/${car.exterior_color.code}/masterJPG/${car.edition_code}_door-5_rim-${car.rim_code}_lamp-${car.lamp_code}_color-${car.exterior_color.code}_view-${index}.jpg`;
    const imageCount = 24;

    return Array
        .from({ length: imageCount }, (v, k) => (k + 1).toString().padStart(2, '0'))
        .map(url);
}
