<script setup>
const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    carModels: {
        type: Array,
        required: true,
    }
});

const CARMODEL_MULTIPLE_DELIMITER = '_';

defineEmits(['update:modelValue']);

function isActive(carModelSlug) {
    if (!props.modelValue) {
        return true;
    }

    if (props.modelValue.includes(CARMODEL_MULTIPLE_DELIMITER)) {
        // multiple models active
        const activeSlugs = props.modelValue.split(CARMODEL_MULTIPLE_DELIMITER);
        return activeSlugs.includes(carModelSlug);
    }

    return props.modelValue === carModelSlug;
}
</script>

<template>
    <div class="grid grid-cols-3 gap-4 lg:grid-cols-5">
        <a
            href="#"
            v-for="carModel in carModels"
            @click.prevent="$emit('update:modelValue', modelValue === carModel.slug ? '' : carModel.slug)"
            class="flex flex-col justify-between scale-100 motion-safe:betterhover:hover:scale-[1.1] motion-safe:betterhover:hover:opacity-100 transition-all duration-250 text-center text-sm lg:text-md"
            :class="isActive(carModel.slug) ? 'opacity-100' : 'opacity-50'"
        >
            <img :src="`/img/models/${carModel.slug}.png`" />
            <div class="flex justify-center items-end gap-2">
                <div class="flex-initial">CUPRA {{ carModel.model }}</div>
                <span class="flex-none inline-flex py-0.5 px-1.5 rounded-full bg-petrol text-white text-xs font-bold">
                    {{ carModel.cars_count }}
                </span>
            </div>
        </a>
    </div>
</template>